import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Header.css";
import "./assets/css/Login.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/Responsive.css";
import "./assets/css/ThemeChange.css";

import Progress from "react-progress-2";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import NotFount from "./errorPage/404";
import Layout from "./Layout";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import Login from "./pages/Login";
import News from "./pages/News";
import PropertyManagement from "./pages/PropertyManagement";

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/property-management" element={<PropertyManagement />} />
          <Route path="/news" element={<News />} />
        </Route>

        <Route path="*" element={<NotFount />} />
      </Routes>
    </div>
  );
}

export default App;
