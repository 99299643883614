import React from "react";
import { useLocation } from "react-router-dom";
import UserDataTable from "../components/User/UserDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function UserManagement() {
  const location = useLocation();
  useBreadCrumb("User Management", location.pathname, "User Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <UserDataTable />
      </div>
    </div>
  );
}
