import React from "react";
import { useLocation } from "react-router-dom";
import DashBoardBox from "../components/DashBoardWidgets/DashBoardBox";
import useBreadCrumb from "../hooks/useBreadCrumb";
import useDashboard from "../hooks/useDashboard";

function Dashboard() {
  const location = useLocation();
  useBreadCrumb("Dashboard", location.pathname, "Dashboard");

  const dashboard = useDashboard();
  return (
    <div className="dashboard">
      <div className="box_section">
        <DashBoardBox
          title="Total Users"
          count={dashboard?.totalUsers}
          icon={"bi bi-people"}
        />
        <DashBoardBox
          title="Total Properties"
          count={dashboard?.totalProperties}
          icon={"bi bi-ui-checks-grid"}
        />
        <DashBoardBox
          title="Pending Properties"
          count={dashboard?.pendingProperties}
          icon={"bi bi-person"}
        />
        <DashBoardBox
          title="Total Sold"
          count={dashboard?.SoldProperties}
          icon={"bi bi-capslock"}
        />
      </div>
    </div>
  );
}

export default Dashboard;
