import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable } from "../../store";
import useProperties from "../../hooks/useProperties";

const { confirm } = Modal;

function PropertyDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
  });

  const properties = useProperties(filterData);

  const statusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "PUBLISH":
        return "green";
      case "UNPUBLISH":
        return "red";
      case "DELETED":
        return "red";
      default:
        return "purple";
    }
  };

  const columns = [
    {
      title: "",
      align: "center",
      dataIndex: "image_path",
      render: (image_path) => (
        <div className="table_av">
          <Avatar
            src={`${IMAGE_PATH + image_path}`}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
      width: 60,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 200,
    },

    {
      title: "Property Type",
      dataIndex: "propertyType",
      width: 100,
    },
    {
      title: "Property Status",
      dataIndex: "propertyStatus",
      width: 100,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.createdAt
          ? moment(record.createdAt).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 150,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag className="tags" color={statusColor(record.status)}>
          {record.status}
        </Tag>
      ),
      fixed: "right",
      width: 80,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="PENDING property">
            <Button
              className="delete_button"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => statusChange(record.id, "PUBLISH")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="UNPUBLISH Property">
            <Button
              className="view_button"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => statusChange(record.id, "UNPUBLISH")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="DELETED Property">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => statusChange(record.id, "DELETED")}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this property?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.PROPERTY_STATUS_CHANGE}/${id}/${type}`).then(
          (result) => {
            let responseJson = result;
            if (responseJson.status === "success") {
              message.success(responseJson.message);
              setRefreshTable(!refreshTable);
              Progress.hide();
            } else if (responseJson.status === "error") {
              message.error(responseJson.message);
              Progress.hide();
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={properties.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: properties.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default PropertyDataTable;
