import React from "react";
import { useLocation } from "react-router-dom";
import PropertyDataTable from "../components/Property/PropertyDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function PropertyManagement() {
  const location = useLocation();
  useBreadCrumb("Property Management", location.pathname, "Property Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <PropertyDataTable />
      </div>
    </div>
  );
}
