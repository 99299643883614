import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import useUsers from "../../hooks/useUsers";

const { confirm } = Modal;

function UserDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
  });

  const users = useUsers(filterData);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 100,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 100,
    },
    {
      title: "Country",
      dataIndex: "country_name",
      width: 100,
    },
    {
      title: "Contact No",
      dataIndex: "mobile_no",
      width: 100,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 150,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {record.status === 1 ? (
            <Tooltip placement="bottom" title="Deactivate Fund">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.id, "Inactive")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Activate Fund">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.id, "Active")}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 50,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this user?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.USERS_STATUS_CHANGE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={users.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: users.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default UserDataTable;
