export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "User Management",
    exact: true,
    to: "/user-management",
    iconClassName: "bi bi-people",
    route_key: "user-management",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Property Management",
    exact: true,
    to: "/property-management",
    iconClassName: "bi bi-archive",
    route_key: "property-management",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "News",
    exact: true,
    to: "/news",
    iconClassName: "bi bi-megaphone",
    route_key: "news",
    privilege: ["SUPER_ADMIN"],
  },
];
