export const LIVE_MAIN_API_URL = "https://api.gleejceylon.com/";
export const BASE_LOCAL_API_URL = "http://localhost:8000/";
export const IMAGE_PATH = "https://ik.imagekit.io/gleejrealestate/";

export const BASE_API_URL = `${LIVE_MAIN_API_URL}`;

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}api/v1/admin/login`,

  USERS_LIST: `${BASE_API_URL}api/v1/admin/users`,
  USERS_STATUS_CHANGE: `${BASE_API_URL}api/v1/admin/user/status-change`,

  PROPERTY_LIST: `${BASE_API_URL}api/v1/properties`,
  PROPERTY_STATUS_CHANGE: `${BASE_API_URL}api/v1/property/status-change`,

  DASHBOARD_DATA: `${BASE_API_URL}api/v1/admin/dashboard`,
};

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "code",
    "codeBlock",
    "|",
  ],
};
